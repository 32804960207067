import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { LoginService } from 'src/app/services/login.service';

const URL = environment.base_url;
const headers = new HttpHeaders()
  .set('Content-Type', 'application/json')
  .set('Accept', 'application/json');


@Injectable({
  providedIn: 'root'
})
export class RefundService {

  constructor(private http: HttpClient, private loginService: LoginService) { }

  getCart(id: number): Observable<any> {
    const header = new HttpHeaders()
                    .set('Content-Type', 'application/json')
                    .set('Accept', 'application/json')
                    .set('Authorization', this.loginService.getSession() || '');

    return this.http.get(`${URL}/api/carts-user/${id}`, { headers: header });
  }

  getInvoices(id: number): Observable<any> {
    const header = new HttpHeaders()
                    .set('Content-Type', 'application/json')
                    .set('Accept', 'application/json')
                    .set('Authorization', this.loginService.getSession() || '');

    return this.http.get(`${URL}/api/invoices/${id}`, { headers: header });
  }

  cartInfo(id: number): Observable<any> {
    const header = new HttpHeaders()
                    .set('Content-Type', 'application/json')
                    .set('Accept', 'application/json')
                    .set('Authorization', this.loginService.getSession() || '');

    return this.http.get(`${URL}/api/cart/${id}`, { headers: header });
  }

  aproveCart(id: number): Observable<any> {
    const header = new HttpHeaders()
                    .set('Content-Type', 'application/json')
                    .set('Accept', 'application/json')
                    .set('Authorization', this.loginService.getSession() || '');

    return this.http.post(`${URL}/api/cart/payComplete/${id}`, {}, { headers: header });
  }

  deleteCart(id: number): Observable<any> {
    const header = new HttpHeaders()
                    .set('Content-Type', 'application/json')
                    .set('Accept', 'application/json')
                    .set('Authorization', this.loginService.getSession() || '');

    return this.http.delete(`${URL}/api/cart/${id}`, { headers: header });
  }
}
