<mat-toolbar id="main-toolbar" class="mat-elevation-z2"> 
  <div class="theme-container" fxLayout="row" fxLayoutAlign="space-between center">
    <span fxHide="false" fxHide.gt-sm>
      <button mat-icon-button (click)="sidenavToggle()">
        <mat-icon class="mat-icon-lg">menu</mat-icon>
      </button> 
    </span>
    <div fxShow="false" fxShow.gt-sm class="horizontal-menu">            
        <app-horizontal-menu [menuParentId]="0"></app-horizontal-menu>
    </div>   
  </div>     
</mat-toolbar>
