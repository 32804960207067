import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

const URL = environment.base_url;
const headers = new HttpHeaders()
  .set('Content-Type', 'application/json')
  .set('Accept', 'application/json');

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private http: HttpClient) { }

  login(email: string, password: string): Observable<any> {
    return this.http.post(`${URL}/api/login`, { email: email, password: password }, { headers });
  }

  me(id: number): Observable<any> {
    const header = new HttpHeaders()
                    .set('Content-Type', 'application/json')
                    .set('Accept', 'application/json')
                    .set('Authorization', this.getSession() || '');

    return this.http.get(`${URL}/api/user/${id}`, { headers: header });
  }

  storeMe(me: any) {
    localStorage.setItem('me_web', JSON.stringify(me));
  }

  getProfile() {
    return JSON.parse(localStorage.getItem('me_web') || '{}');
  }

  getMe() {
    return localStorage.getItem('me_web') || null;
  }

  getSession() {
    return localStorage.getItem('token_web') || null;
  }

  async storeSession(token_type: string, access_token: string) {
    await localStorage.setItem('token_web', `${token_type} ${access_token}`);
    
    return this.getSession();
  }

  async storeRemember(value: any) {
    await localStorage.setItem('email', value.username);
    await localStorage.setItem('remember', value.remember ? '1' : '0');
  }

  remember() {
    let remember = localStorage.getItem('remember') || false;

    if(Number(remember) === 1) {
      return true;
    }
    return false;
  }

  email() {
    return localStorage.getItem('email');
  }

  async logout() {
    localStorage.removeItem('token_web');
  }

  postUser(model: any): Observable<any> {
    return this.http.post(`${URL}/api/register`, model);
  }

  getCode(model: any): Observable<any> {
    return this.http.post(`${URL}/api/verify-code`, model);
  }

  resetPass(model: any): Observable<any> {
    return this.http.post(`${URL}/api/change-password`, model);
  }

  updateUser(id: number, model: any): Observable<any> {
    const header = new HttpHeaders()
                    .set('Content-Type', 'application/json')
                    .set('Accept', 'application/json')
                    .set('Authorization', this.getSession() || '');

    return this.http.put(`${URL}/api/user/${id}`, model, { headers: header });
  }
}
