<div class="header bg-primary">
    <div fxLayout="row wrap" fxLayoutAlign="space-between center">
        <h2 class="fw-500 secondary-font">Order</h2>
        <app-dialog-header-controls [dialogRef]="dialogRef"></app-dialog-header-controls> 
    </div>   
</div> 
<mat-dialog-content>  
    <div class="pt-3"> 

        <div fxLayout="row wrap" class="flex-wrapper"> 
            
            <div fxFlex="100" class="px-3 mb-2">
                <h2 class="secondary-font">Shopping Cart</h2>
            </div>
            <div fxFlex="100" class="px-3 mb-5">
                <div class="divider"></div>
                <div class="mat-table theme-table review-order-table">
                    <div class="mat-header-row">
                        <div class="mat-header-cell">Package</div>
                        <div class="mat-header-cell price">Price</div>      
                        <div class="mat-header-cell quantity">Applications</div>
                    </div> 
                    <div *ngFor="let item of data.packages" class="mat-row">
                        <div class="mat-cell">{{item.name}}</div>
                        <div class="mat-cell price">  
                            <span class="primary-color py-3">  
                                {{ ( item.cost ) | currency : 'USD' : 'symbol' : '1.2-2'}}
                            </span>  
                        </div>      
                        <div class="mat-cell quantity">{{item.applications}}</div>
                    </div> 
                    <div class="mat-row">
                        <div class="mat-cell"></div>
                        <div class="mat-cell price"></div>
                        <div class="mat-cell quantity text-right">
                        <div fxLayout="column" fxLayoutAlign="center end" class="grand-total px-2">
                            <span>Grand total:</span>
                        </div>        
                        </div>
                        <div class="mat-cell total">
                            <div fxLayout="column" fxLayoutAlign="center start" class="grand-total">
                                <span>{{ total | currency : 'USD' : 'symbol' : '1.2-2'}}</span>
                            </div>
                        </div> 
                    </div>
                </div>
            </div> 
            
        </div>   

    </div>  
</mat-dialog-content> 