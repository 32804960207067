import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RefundService } from 'src/app/admin/refund/refund.service';
import { Order } from 'src/app/app.models';

@Component({
  selector: 'app-order-details-dialog',
  templateUrl: './order-details-dialog.component.html',
  styleUrls: ['./order-details-dialog.component.scss']
})
export class OrderDetailsDialogComponent implements OnInit {

  data: any = []
  total: number = 0;

  constructor(public dialogRef: MatDialogRef<OrderDetailsDialogComponent>, private refundService: RefundService,
              @Inject(MAT_DIALOG_DATA) public order: Order) { }
 

  async ngOnInit() {
    this.data = await this.refundService.cartInfo(Number(this.order)).toPromise();
    for(let item of this.data.packages) {
      this.total = this.total + Number(item.cost);
    }
  }

}
