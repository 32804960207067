import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from 'src/app/services/login.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private loginService: LoginService, private router: Router) {
  }
  
  canActivate() {
    if (this.loginService.getSession()) {
      return this.router.navigate(['/']).then(() => false);
    }
    return true;
  }

}
