<ng-container *ngFor="let menuItem of menuItems">

  <a *ngIf="menuItem.routerLink && !menuItem.hasSubMenu" mat-button [routerLink]="[menuItem.routerLink]">
    <span>{{ menuItem.title | translate }}</span>
  </a>

  <mat-menu #menu="matMenu" class="horizontal-sub-menu">
      <app-horizontal-menu [menuParentId]="menuItem.id"></app-horizontal-menu>
  </mat-menu>
    
</ng-container>